<template>
  <div class="container">
    <content-title :nav='nav'></content-title>
    <!-- 新增 -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="移动端" name="first">
        <div class="">
          <div class="top-up">
            <span @click="addVisible = true">新增</span>
          </div>
          <div class="tables">
            <el-table :data="dataBanner" :header-cell-style="{ background: '#EAEAEA', color: '#434343' }" border
              style="width: 100%" ref="multipleTable">
              <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
              <el-table-column label="Banner" align="center">
                <template slot-scope="scope">
                  <el-image :src="scope.row.pic" style="height:3rem" />
                </template>
              </el-table-column>
              <el-table-column prop="activity_id" label="活动id" align="center"></el-table-column>
              <el-table-column prop="banner_name" label="Banner名称" align="center"></el-table-column>
              <el-table-column prop="type" label="类型" align="center">
              </el-table-column>
              <el-table-column prop="type" label="类型说明" align="center">
                <template slot-scope='scope'>
                  {{typeName(scope.row.type)}}
                </template>
              </el-table-column>
              <el-table-column prop="state" label="状态" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.state==1" style="color:green">生效</span>
                  <span v-if="scope.row.state==2" style="color:red">未生效</span>
                </template>

              </el-table-column>
              <el-table-column prop="start_time" label="开始时间" align="center"></el-table-column>
              <el-table-column prop="end_time" label="截止时间" align="center"></el-table-column>
              <el-table-column fixed="right" label="操作" align="center">
                <template slot-scope="scope">
                  <el-button size="small" type="primary" @click="openUpdate(scope.row)">编辑</el-button>
                  <el-button size="small" type="danger" v-if='scope.row.state == 1' @click="frozen(scope.row,2)">
                    下架
                  </el-button>
                  <el-button size="small" type="warning" v-if='scope.row.state == 2' @click="frozen(scope.row,1)">
                    上架
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 分页 -->
          <div class="pagination">
            <el-pagination background @current-change="currentPageChange" :current-page.sync="currentPage3"
              :page-size="10" layout="prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>

      </el-tab-pane>

      <!-- <el-tab-pane label="网页端" name="second">
        <div class="navs">
          <label for="">广告类型</label>
          <el-select v-model="adType">
            <el-option v-for="(navsitem,navsindexs) in navs" :key="navsindexs" :label="navsitem" :value="navsitem">
            </el-option>
          </el-select>
          <el-button @click="Asshow =true" type="primary" style="float:right;">新增</el-button>
        </div>
        <el-table :data="dataBanner" :header-cell-style="{background:'#EAEAEA',color:'#434343'}" border
          style="width: 100%">
          <el-table-column prop="id" label="序号" align="center"></el-table-column>
          <el-table-column height="219" prop="source_url" width="300" label="Banner">
            <template slot-scope="scope">
              <img :src="scope.row.photo" alt />
            </template>
          </el-table-column>
          <el-table-column prop="theme" label="Banner名称" align="center"></el-table-column>
          <el-table-column prop="fileds" label="Banner位置" align="center"></el-table-column>
          <el-table-column prop="sortName" label="浏览数" align="center"></el-table-column>
          <el-table-column prop="orderBy" label="点击数" align="center"></el-table-column>
          <el-table-column prop="fileds" label="点击率" align="center"></el-table-column>
          <el-table-column :prop="state == 1?'已上架':'已过期'" label="状态" align="center"></el-table-column>
          <el-table-column prop="create_time" width="180" label="购买时间" align="center"></el-table-column>
          <el-table-column prop="update_time" width="180" label="截止时间" align="center"></el-table-column>
          <el-table-column fixed="right" label="操作" width="150" align="center">
            <el-button class="offSheIf" type="info" @click="flagD = true" plain>下架</el-button>
            <el-button class="listEdit" type="info" plain>编辑</el-button>
            <el-button type="danger" @click="flagD = true" plain>删除</el-button>
          </el-table-column>
        </el-table>
      </el-tab-pane> -->
    </el-tabs>

    <!-- 添加广告标题弹窗 -->
    <el-dialog :visible='addVisible || updateVisible' title="新增">
      <el-form v-model="form" label-width="auto">
        <el-form-item label="图片">
          <avatar-uploader @getImgUrl='uploadPic' :url='temp.pic'></avatar-uploader>
        </el-form-item>
        <el-form-item class="add_title_form" label='广告名称'>
          <el-input type="text" v-model="form.banner_name" :placeholder="temp.banner_name||'请输入广告标题'"></el-input>
        </el-form-item>
        <!-- <el-form-item class="add_title_form" label='广告描述'>
          <el-input type="text" v-model="form.banner_descr" :placeholder="temp.banner_name||'请输入广告标题'"></el-input>
        </el-form-item> -->
        <el-form-item label="广告类型">
          <el-select v-model="form.type" :placeholder="typeName(temp.type)|| '请选择广告类型' ">
            <el-option v-for="item,index in banner_type " :key="index" :label="item.title" :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告生效时间">
          <el-date-picker v-model="timeRange" type="datetimerange" range-separator="至" start-placeholder="开始日期"
            @change="selecTimeRange" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>

      </el-form>

      <span slot='footer'>
        <el-button type="primary" v-if='addVisible' @click="add()">确定</el-button>
        <el-button type="primary" v-if='updateVisible' @click="update()">确定</el-button>
        <el-button @click="handleClose">取消</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
export default {
  name: 'MobileAd',
  data() {
    return {
      nav: {
        firstNav: '广告管理中心',
        secondNav: '广告列表',
      },
      navs: [],
      form: {
        mechanism_id: -1,
        state: 1,
      },
      temp: {},
      timeRange: [],
      banner_type: [],
      addVisible: false,
      updateVisible: false,
      activeName: 'first',
      Selhelp: false,
      dataBanner: [], //数据列表
      sels: [], //当前列数据
      state: [], //数据状态
      navsindex: 0, //navs索引
      currentPage: 1, //页数
      currentPage3: 1,
      currentPageteacher: 1,

      total: 0, //总条数
    }
  },
  computed: {},
  mounted() {
    this.getAdType()
    this.getAdList()
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    typeName(type) {
      let a = this.banner_type.find((item) => {
        return item.type == type
      })
      if (a) {
        return a.title
      } else return null
    },
    selecTimeRange(v) {
      this.form.start_time = new Date(v[0]).Format('yyyy-MM-dd hh:mm:ss')
      this.form.end_time = new Date(v[1]).Format('yyyy-MM-dd hh:mm:ss')
    },
    // 分页
    currentPageChange(val) {
      this.currentPage = val
      this.getAdList()
    },
    // 广告位置列表
    getAdType() {
      this.$axios
        .get('/user/bannerType/queryManagerListPage', {
          params: {
            pageSize: 99,
            currentPage: 1,
            status: 2,
          },
        })
        .then((res) => {
          this.banner_type = res.data.data.rows
        })
    },
    //添加广告
    add() {
      let url = '/user/exhibitionPic/insert'

      // 编辑
      this.$axios.post(url, this.form).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: 'success', message: '添加成功!' })
          this.form = {
            mechanism_id: -1,
            state: 1,
          }
          this.timeRange = []
          this.getAdList()
        }
      })
    },
    openUpdate(row) {
      this.temp = row
      this.form.id = row.id
      this.timeRange = [row.start_time, row.end_time]
      this.updateVisible = true
    },
    update() {
      let url = '/user/exhibitionPic/update'
      this.$axios
        .post(url, {
          ...this.form,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: 'success', message: '修改成功!' })
            this.form = {
              mechanism_id: -1,
              state: 1,
            }
            this.temp = {}
            this.updateVisible = false
            this.getAdList()
          }
        })
    },
    getAdList() {
      //获取列表数据
      this.$axios
        .get('/user/exhibitionPic/queryManagerListPage', {
          params: {
            mechanism_id: -1,
            pageSize: 10,
            currentPage: this.currentPage,
          },
        })
        .then((res) => {
          let index = 0
          this.dataBanner = res.data.data.rows
          this.total = res.data.data.total
        })
    },
    uploadPic(v) {
      this.form.pic = v
    },
    frozen(row, state) {
      let url = '/user/exhibitionPic/update'
      this.$axios
        .post(url, {
          id: row.id,
          state: state,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: 'success', message: '添加成功!' })
            this.form = {}
            this.getAdList()
          }
        })
    },
    offShelf() {
      // 下架商品
      let stateId
      if (this.sels.state == 1) {
        stateId = 2
      } else {
        stateId = 1
      }
      let vals = {
        id: this.sels.id,
        state: stateId,
      }
      this.$axios.post('/user/exhibitionPic/update', vals).then((res) => {
        let trpe = ''
        if (this.navsindex == 0) {
          trpe = 'life_live_banner'
        } else if (this.navsindex == 1) {
          trpe = 'live_banner'
        } else if (this.navsindex == 2) {
          trpe = 'mechanism_hot'
        } else if (this.navsindex == 3) {
          trpe = 'look_banner'
        } else if (this.navsindex == 4) {
          trpe = 'master_title'
        } else if (this.navsindex == 5) {
          trpe = 'advertisement'
        }
        this.toclose()
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.form = {
            mechanism_id: -1,
            state: 1,
          }
          this.temp = {}
          this.timeRange = []
          this.addVisible = false
          this.updateVisible = false
          done()
        })
        .catch((_) => {})
    },
  },
}
</script>
<style  scoped>
.add_title p {
  width: 100%;
  text-align: center;
  font-size: 32px;
}
.add_title_form {
  margin: 16px 0;
}
.add_title_form label {
  font-size: 22px;
}
.add_title_input {
  width: 250px;
  margin-left: 20px;
}
.add_title_btn {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 24px 0;
}
.PERInformation {
  position: relative;
  width: 85.5%;
  background: #ffffff;
  min-height: 1200px;
}
.PERInformation-head {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
}
.PERInformation-head > .el-breadcrumb > .el-breadcrumb__item >>> .el-breadcrumb__inner {
  font-size: 20px;
  color: #434343;
  /* font-weight: bold; */
}
.PERInformation-head > .el-breadcrumb > .el-breadcrumb__item >>> .el-breadcrumb__separator {
  color: #47cecf;
  width: 11px;
  color: 15px;
}
.PERInformation-head > .navs {
  margin-left: auto;
}
.navs > ul {
  display: flex;
  list-style: none;
  padding: 0;
}
.navs > ul > li {
  margin-left: 40px;
  cursor: pointer;
  color: #434343;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  /* font-weight: bold; */
}
.navsed {
  color: #32c1c2 !important;
  border-bottom: 3px solid #32c1c2;
}
/* 新增按钮 */
.top-up {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}
.top-up > span {
  display: inline-block;
  width: 74px;
  height: 32px;
  background: rgba(255, 65, 65, 1);
  border-radius: 5px;
  text-align: center;
  line-height: 32px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

/* 弹框 */
.formTitle {
  font-size: 24px;
  font-weight: bold;
  margin-left: 140px;
}
.login {
  position: fixed;
  font-size: 24px;
  /* height: 800px; */
  width: 500px;
  border-radius: 10px;
  background-color: #ffffff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2405;
}
.openhelp {
  overflow: hidden;
  overflow-y: auto;
  padding: 130px;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2500;
}
.openhelp h2 {
  color: #32c1c2;
  padding-left: 20px;
}
.downhelp {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #111111;
  z-index: 2450;
  opacity: 0.7;
}
.popupcontents {
  position: relative;
  width: 100%;
  height: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.over {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #111111;
  z-index: 1000;
  opacity: 0.7;
}

.offSheIf {
  margin-left: 10px;
}
.listEdit {
  margin: 10px 0;
  margin-left: 10px;
}

.deleteAd {
  display: block;
  font-weight: bold;
  color: #414141;
  text-align: center;
  margin: 15px 0;
}

.formTitle {
  font-size: 24px;
  font-weight: bold;
  display: block;
  text-align: center;
  color: #414141;
  margin: 22px 0;
}
.btns {
  text-align: center;
  margin: 50px 0 20px 0;
}

/* 上传图片 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  min-width: 70px;
  height: 78px;
  display: block;
}

/*  */
.image {
  width: 170px;
  height: 170px;
  margin-left: 20px;
  border-radius: 10px;
  position: relative;
}
.image img,
input {
  width: 170px;
  height: 170px;
  border-radius: 10px;
}
.image input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 9999;
  /* // background-color #000 */
}
.agein {
  text-align: right;
}
</style>